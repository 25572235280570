@charset "utf-8";

// -------------------------------------------------- MESSAGE・WORKS
.l-intro {
	> * {
		position: relative;
	}
	.lead {
		* { letter-spacing: inherit; }
		span { display: block; }
	}
	.copy {
		margin: .8em 0 1em;
		.box {
			padding-top: 0;
			padding-bottom: 0;
			&:not(:first-child) { margin-top: .3em; }
		}
	}
	.txt span {
		display: block;
	}
	.wrap {
		overflow: hidden;
	}
	// visual
	.l-visual {
		> * { position: absolute; }
		.shape {
			top: 0;
			z-index: -1;
		}
		.js-luxy {
			transform-style: preserve-3d;
			//backface-visibility: hidden;
		}
		.shape > * {
			position: absolute;
			bottom: -130px;
			opacity: .45;
		}
		img {
			max-width: 100%;
			height: auto;
		}
	}
}
// タイトル
section.js-inview {
	&:not(.in-view) {
		.c-topTitle .wrap span { transform: translateY(1.4em); }
		.c-topTitle::after { height: 0; }
		.l-visual {
			.boy, .girl, .boy-smp, .girl-smp {
				opacity: 0;
				transform: translateY(16px);
			}
		}
	}
	&.in-view {
		.c-topTitle .wrap span {
			transition: opacity .3s, transform .3s $easeOutCubic;
		}
		.c-topTitle::after {
			transition: height .3s $easeOutCubic;
		}
	}
}
body:not(.paging) {
	section.js-inview.in-view {
		.c-topTitle sup.wrap span { transition-delay: .1s; }
		.c-topTitle b.wrap span { transition-delay: .15s; transition-duration: .4s; }
		.c-topTitle .ja.wrap span { transition-delay: .35s; }
		.c-topTitle::after { transition-delay: .45s; }
	}
}
// ヴィジュアル
.l-intro.js-inview {
	&:not(.in-view) {
		&.message-intro .l-visual .shape {
			clip-path: polygon(100% 0, 100% 0, 61.5% 100%, 61.5% 100%);
		}
		&.works-intro .l-visual .shape {
			clip-path: polygon(0 0, 0 0, 38.5% 100%, 38.5% 100%) !important;
		}
	}
	&.in-view {
		.l-visual {
			.shape {
				 transition: opacity .3s, clip-path .3s $easeOutCubic; 
				 + .shape { transition-delay: .2s; }
			}
			.boy, .girl, .boy-smp, .girl-smp {
				transition: opacity .4s, transform .4s $easeOutCubic;
			}
			.boy { transition-delay: .3s; }
			.girl { transition-delay: .4s; }
			.boy-smp { transition-delay: .45s; }
			.girl-smp { transition-delay: .5s; }
		}
	}
}
body:not(.paging) {
	.l-intro.js-inview.in-view {
		.c-topTitle sup.wrap span { transition-delay: .4s; }
		.c-topTitle b.wrap span { transition-delay: .45s; }
		.c-topTitle .ja.wrap span { transition-delay: .65s; }
		.c-topTitle::after { transition-delay: .75s; }
	}
}
body.paging.send {
	.l-intro.js-inview.in-view {
		.c-topTitle sup.wrap span,
		.c-topTitle b.wrap span,
		.c-topTitle .ja.wrap span,
		.c-topTitle::after { transition-delay: 0s; }
	}
}
@include OVER() {
	.l-intro {
		position: relative;
	}
	.l-topContents {
		.l-intro {
			padding: 220px 0;
		}
		.message-intro {
			padding-top: 370px;
		}
		.interview-section {
			padding: 160px 0 80px;
		}
		.blog-section {
			padding: 80px 0 160px;
		}
	}
	.l-intro .copy {
		line-height: 1;
	}
	.l-intro .l-visual {
		position: absolute;
		top: 0;
		left: 0;
		z-index: -1;
		width: 100%;
	}
	.l-intro .l-visual .shape {
		width: 828px;
		height: 540px;
		background: #523fce;
	}
	.l-container:not(.isIE):not(.isEdge) {
		.l-intro .l-visual.in-view .shape {
			transition: clip-path .3s .2s $easeOutCubic;
		}
		.l-intro .l-visual .shape:empty {
			background: #000;
		}
		.l-intro .l-visual.in-view .shape:empty {
			transition-delay: 0s;
			transition-timing-function: $easeOutCubic;
		}
	}
	/* forIE */
	.l-intro.js-inview:not(.in-view) .l-visual .shape {
		opacity: 0;
	}
	.l-container.isIE,
	.l-container.isEdge {
		.l-intro .l-visual .shape {
			background: center / cover no-repeat;
		}
		.l-intro.js-inview.in-view .l-visual .shape {
			transition: opacity .6s;
		}
		.l-intro .l-visual .shape > * {
			display: none;
		}
	}
}
@include UNDER() {
  .l-topContents {
    .works-intro,
    .interview-section,
    .blog-section {
			padding-top: 115px;
		}
	}
	.l-intro {
		.copy {
			margin: 1.5em 0;
		}
	}
	.l-visual {
		z-index: -1;
		margin-right: calc(-48vw / 7.5);
		margin-left: calc(-48vw / 7.5);
		.shape {
			width: 100vw;
			height: calc(680vw / 7.5);
			margin-left: calc(50% - 50vw);
			> * { display: none; }
		}
	}
	.l-intro.js-inview:not(.in-view) .l-visual .shape {
		opacity: 0;
	}
	.l-intro.js-inview.in-view .l-visual .shape {
		transition: opacity .6s;
	}
}

// -------------------------------------------------- MESSAGE
.message-intro.js-inview {
	// 出現前
	&:not(.in-view) {
		.l-txtContainer {
			.lead .wrap span,
			.copy .box::before,
			.copy .box .wrap span,
			.txt .wrap span {
				opacity: 0;
			}
			.lead .wrap span { transform: translateY(1.8em); }
			.copy .box::before { transform: scaleX(0); transform-origin: 0 0; }
			.copy .box .wrap span { transform: translateY(1.4em); }
			.txt .wrap span { transform: translateY(2em); }
		}
		.c-link {
			opacity: 0;
			transform: translateY(16px);
		}
	}
	// transition・timing
	&.in-view {
		// テキスト
		.l-txtContainer {
			.lead .wrap {
				span { transition: opacity .4s, transform .4s $easeOutCubic; }
				&:nth-child(1) span { transition-delay: .8s; }
				&:nth-child(2) span { transition-delay: .85s; }
			}
			.copy .box {
				&::before { transition: transform .6s $easeOutCubic; }
				.wrap span { transition: opacity .4s, transform .4s $easeOutCubic; }
				
				&:nth-of-type(1) {
					&::before { transition-delay: .9s; }
					@for $value from 1 to 20 {
						.wrap span:nth-child(#{$value}) { transition-delay: .9s + .05s * $value; }
					}
				}
				&:nth-of-type(2) {
					&::before { transition-delay: 1s; }
					@for $value from 1 to 20 {
						.wrap span:nth-child(#{$value}) { transition-delay: 1s + .05s * $value; }
					}
				}
				&:nth-of-type(3) {
					&::before { transition-delay: 1.1s; }
					@for $value from 1 to 20 {
						.wrap span:nth-child(#{$value}) { transition-delay: 1.1s + .05s * $value; }
					}
				}
			}
			.txt .wrap {
				span { transition: opacity .4s, transform .4s $easeOutCubic; }
				@for $value from 1 to 20 {
					&:nth-child(#{$value}) span { transition-delay: 1.2s + .05s * $value; }
				}
			}
		}
		.c-link {
			transition: opacity .4s, transform .4s $easeOutCubic;
			@include OVER() { transition-delay: 1.7s; }
			@include UNDER() { transition-delay: 2.2s; }
		}
	}
}
@include OVER() {
	.l-topContents .message-intro {
		.l-visual { top: 144px; }
	}
	.message-intro .l-visual {
		top: 0;
		right: -180px;
		left: auto;
		width: 828px;
		height: 878px;
		.boy {
			top: -30px;
			right: 140px;
			z-index: 1;
			width: 615px;
		}
		.girl {
			top: 232px;
			right: 90px;
			width: 380px;
		}
		.boy-smp {
			top: 595px;
			right: 488px;
			width: 60px;
		}
		.girl-smp {
			top: 56px;
			right: 122px;
			z-index: -1;
			width: 126px;
		}
		.shape {
			top: 170px;
			right: 0;
			.bg-boy {
				left: -62px;
				width: 828px;
				height: 576px;
        #indexPage & {
          bottom: -14px;
        }
			}
			.bg-girl {
				right: -13px;
        top: -76px;
				width: 828px;
				height: 576px;
        #indexPage & {
          top: -150px;
        }
			}
		}
	}
	.l-container:not(.isIE):not(.isEdge) {
		.l-intro .shape {
			clip-path: polygon(37.2% 0,100% 0,62.7% 100%,0 100%);
		}
	}
	/* forIE */
	.l-container.isIE .message-intro .l-visual .shape,
	.l-container.isEdge .message-intro .l-visual .shape {
		background-image: url(../img/top/concept_shape.png);
	}
}
@include UNDER() {
	#conceptPage .l-intro .l-visual .shape { display: none; }
	.message-intro .l-visual {
		top: -48px;
		height: calc(820vw / 7.5);
		margin: 0 auto;
		.boy {
			top: 0;
			left: calc(-24vw / 7.5);
			width: calc(800vw / 7.5);
      z-index: 2;
		}
		.girl {
			top: calc(260vw / 7.5);
			right: calc(-83vw / 7.5);
			width: calc(512vw / 7.5);
      z-index: 1;
		}
		.boy-smp {
			top: calc(585vw / 7.5);
			left: calc(80vw / 7.5);
			width: calc(97vw / 7.5);
      z-index: 3;
		}
		.girl-smp {
			top: calc(-86vw / 7.5);
			right: calc(-21vw / 7.5);
			width: calc(138vw / 7.5);
      z-index: 3;
		}
	}
	.l-intro {
		.shape::after {
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			content: "";
			background: #523fce;
			transform: skewY(-45deg);
		}
	}
}
// 下層用
#conceptPage.paging {
	&.in.send {
		.l-visual {
			.boy, .girl, .boy-smp, .girl-smp {
				opacity: 0;
				transform: translateY(16px);
			}
			.shape {
				clip-path: polygon(100% 0, 100% 0, 61.5% 100%, 61.5% 100%);
			}
		}
	}
	&:not(.send) {
		.c-topTitle b.wrap span { transition-delay: .15s; transition-duration: .4s; }
		.c-topTitle .ja.wrap span { transition-delay: .35s; }
		.c-topTitle::after { transition-delay: .45s; }
		.l-visual {
			.shape {
				 transition: opacity .3s, clip-path .3s $easeOutCubic; 
				 + .shape { transition-delay: .2s; }
			}
			.boy, .girl, .boy-smp, .girl-smp {
				transition: opacity .4s, transform .4s $easeOutCubic;
			}
			.boy { transition-delay: .3s; }
			.girl { transition-delay: .4s; }
			.boy-smp { transition-delay: .45s; }
			.girl-smp { transition-delay: .5s; }
		}
	}
	// 出現前
	&.in.send {
		.l-txtContainer {
			.lead .wrap span,
			.copy .box::before,
			.copy .box .wrap span,
			.txt .wrap span {
				opacity: 0;
			}
			.lead .wrap span { transform: translateY(1.8em); }
			.copy .box::before { transform: scaleX(0); transform-origin: 0 0; }
			.copy .box .wrap span { transform: translateY(1.4em); }
			.txt .wrap span { transform: translateY(2em); }
		}
		.c-link {
			opacity: 0;
			transform: translateY(16px);
		}
	}
	// transition・timing
	&:not(.send) {
		// テキスト
		.c-topTitle b.wrap span { transition-delay: .45s; transition-duration: .4s; }
		.c-topTitle .ja.wrap span { transition-delay: .65s; }
		.c-topTitle::after { transition-delay: .75s; }
		.l-txtContainer {
			.lead .wrap {
				span { transition: opacity .4s, transform .4s $easeOutCubic; }
				&:nth-child(1) span { transition-delay: .8s; }
				&:nth-child(2) span { transition-delay: .85s; }
			}
			.copy .box {
				&::before { transition: transform .6s $easeOutCubic; }
				.wrap span { transition: opacity .4s, transform .4s $easeOutCubic; }
				
				&:nth-of-type(1) {
					&::before { transition-delay: .9s; }
					@for $value from 1 to 20 {
						.wrap span:nth-child(#{$value}) { transition-delay: .9s + .05s * $value; }
					}
				}
				&:nth-of-type(2) {
					&::before { transition-delay: 1s; }
					@for $value from 1 to 20 {
						.wrap span:nth-child(#{$value}) { transition-delay: 1s + .05s * $value; }
					}
				}
				&:nth-of-type(3) {
					&::before { transition-delay: 1.1s; }
					@for $value from 1 to 20 {
						.wrap span:nth-child(#{$value}) { transition-delay: 1.1s + .05s * $value; }
					}
				}
			}
			.txt .wrap {
				span { transition: opacity .4s, transform .4s $easeOutCubic; }
				@for $value from 1 to 20 {
					&:nth-child(#{$value}) span { transition-delay: 1.2s + .05s * $value; }
				}
			}
		}
		.c-link {
			transition: opacity .4s, transform .4s $easeOutCubic;
			@include OVER() { transition-delay: 1.7s; }
			@include UNDER() { transition-delay: 2.2s; }
		}
	}
}

// -------------------------------------------------- WORKS
.works-intro.js-inview {
	// 出現前
	&:not(.in-view) {
		.l-txtContainer .txt,
		.c-link {
			opacity: 0;
			transform: translateY(16px);
		}
	}
	// transition・timing
	&.in-view {
		.l-txtContainer .txt,
		.c-link { transition: opacity .4s, transform .4s $easeOutCubic; }
		.l-txtContainer .txt { transition-delay: .8s; }
		.c-link { transition-delay: .9s; }
	}
}
@include OVER() {
	.l-topContents .works-intro {
		padding-bottom: 320px;
		padding-left: 60%;
	}
	.works-intro {
		.l-visual {
			top: 0;
			left: -160px;
			width: 828px;
			height: 953px;
			.boy {
				top: 37px;
				left: -44px;
				width: 737px;
			}
			.girl {
				top: 234px;
				left: -69px;
				width: 520px;
			}
			.boy-smp {
				top: 472px;
				left: 473px;;
				width: 126px;
			}
			.girl-smp {
				top: 220px;
				left: 94px;
				width: 60px;
			}
			.shape {
				top: 230px;
				left: 0;
				.bg-boy {
					right: 0;
          bottom: -136px;
					width: 828px;
					height: 576px;
				}
				.bg-girl {
					left: 0;
          bottom: 15px;
          width: 823px;
          height: 576px;
				}
			}
		}
	}
	.l-container:not(.isIE):not(.isEdge) {
		 .works-intro .l-visual .shape {
			clip-path: polygon(0 0, 61.5% 0, 100% 100%, 38.5% 100%);
		}
	}
	// WORKS ページでは
	#worksPage {
		.works-intro .l-visual {
			top: -104px;
			left: 50.7%;
			width: 470px;
			height: 620px;
			.boy {
				top: 0;
				left: 27px;
				width: 490px;
			}
			.girl {
				top: 100px;
				left: 0;
				width: 354px;
			}
		}
	}
	/* forIE */
	.l-container.isIE .works-intro .l-visual .shape,
	.l-container.isEdge .works-intro .l-visual .shape {
		background-image: url(../img/top/senpai_shape.png);
	}
}
@include UNDER() {
	.works-intro .l-visual {
		top: -48px;
		height: calc(720vw / 7.5);
		.boy {
			top: calc(-50vw / 7.5);
			right: calc(-67vw / 7.5);
			width: calc(900vw / 7.5);
		}
		.girl {
			top: calc(200vw / 7.5);
			left: calc(-75vw / 7.5);
			width: calc(600vw / 7.5);
		}
		.boy-smp {
			top: calc(540vw / 7.5);
			right: calc(24vw / 7.5);
			width: calc(138vw / 7.5);
		}
		.girl-smp {
			top: calc(120vw / 7.5);
			left: calc(32vw / 7.5);
			width: calc(97vw / 7.5);
		}
		.shape::after {
			transform: skewY(45deg);
		}
	}
}

// -------------------------------------------------- INTERVIWE / BLOG
.interview-section.js-inview,
.blog-section.js-inview {
	&:not(.in-view) {
		.c-link {
			opacity: 0;
			transform: translateY(16px);
		}
	}
	&.in-view {
		.c-link {
			transition: opacity .4s, transform .4s $easeOutCubic;
			transition-delay: .85s;
		}
	}
}
