@charset "utf-8";

/*
	.c-btn:            ボタン
	.c-tglBtn:         スマホメニューの出し入れボタン
	.c-topTitle:       タイトル
	.c-pageLead:       リード文
	.c-boxTxt:         ネガ
	.c-boxInline:      ネガインライン
	.c-link:           トップページのリンク
	.success-list:     SUCCESS
	.reason-list:      REASON
	.works-list:       仕事一覧
	.interview-list:   インタビュー一覧
	.blog-list:        ブログ一覧
	.welfare-list:     WELFARE
	.security-section: SECURITY
*/

.svgClipPath {
	position: absolute;
	width: 1px;
	height: 1px;
}

// -------------------------------------------------- ボタン
.c-btn {
	background: #3d9bcc;
  @include UNDER() {
    background: #553dcc;
  }
	a {
		position: relative;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		padding: 0 40px;
		overflow: hidden;
		letter-spacing: .05em;
		background: linear-gradient(to left, #553dcc 126px, rgba(85,61,204,0) 630px) 100% 0 / 756px 40px;
		transition: background 3s;
		&:hover {
			background-position: 0 0;
		}
	}
	span {
		display: block;
	}
	.jp {
		color: #ac9eff;
	}
	&.ef {
		position: relative;
		overflow: hidden;
	}
	&.entry-btn .ico {
		position: absolute;
		top: calc(50% - 7px);
		right: 32px;
		display: block;
		width: 22px;
		height: 14px;
		background: url(/img/common/icon_www.svg) center / 22px auto no-repeat;
    @include UNDER() {
      right: 20px;
    }
	}
  &__www {
    width: 22px;
    height: 14px;
    vertical-align: text-top;
  }
}

// -------------------------------------------------- toggle button
.c-tglBtn {
	margin-left: auto;
	button {
		position: relative;
		display: block;
		width: 46px;
		height: 46px;
		padding: 0;
		background: none;
		border: 0;
		span {
			position: absolute;
			top: calc(50% - 1.5px);
			left: calc(50% - 13px);
			display: block;
			width: 26px;
			height: 3px;
			pointer-events: none;
			content: "";
			background: #fff;
		}
	}
}

// -------------------------------------------------- タイトル
.c-topTitle {
	position: relative;
	z-index: 1;
  padding-bottom: 24px;
  margin-bottom: 64px;
  @include UNDER() {
    body:not(#indexPage) & { margin-bottom: 32px;}
  }
  @include OVER() {
    #indexPage & {
      padding-bottom: 75px;
      margin-bottom: 50px;
    }
    #indexPage .interview-section &,
    #indexPage .blog-section & {
      margin-bottom: 72px;
    }
    body:not(#indexPage) &,
    section:not(.l-entry) & {
      padding-bottom: 75px;
      margin-bottom: 72px;
    }
  }
	> * {
		display: block;
		overflow: hidden;
	}
	span:not([class]) {
		display: inline-block;
	}
	sup {
		position: static;
		white-space: nowrap;
		img {
			width: 138px;
			height: 8px;
			vertical-align: middle;
		}
	}
	b {
		margin-bottom: 8px;
	}
  .ja.wrap {
    text-transform: none;
  }
  &::after {
		position: absolute;
		bottom: 0;
		left: 0;
		width: 68px;
		height: 6px;
		content: "";
		background: linear-gradient(
			-36deg,
			rgba(255,255,255,0) 28%, #fff 30%,
			#fff 54%, rgba(255,255,255,0) 56%,
			rgba(255,255,255,0) 70%, #fff 72%, #fff
		) 32% 50% / 14px 14px;
    .success-section &
    .reason-section & {
      display: none;
    }
	}
	@include UNDER() {
		&.l-pageTitle::after {
			display: none;
		}
	}
}

// -------------------------------------------------- リード
.c-pageLead {
}

// -------------------------------------------------- ネガ
.c-boxTxt {
	position: relative;
	color: #0c0c0c;
	.box {
		position: relative;
		display: inline-block;
		padding: 4px .5em;
		overflow: hidden;
		line-height: 1.7;
		vertical-align: middle;
		@include UNDER() {
			padding: 4px .6em;
		}
		&:not(:first-child) {
			margin-top: 8px;
		}
		&::before {
			position: absolute;
			top: 0;
			left: 0;
			z-index: 1;
			width: 100%;
			height: 100%;
			content: "";
			background: #fff;
			transform-origin: 0 0;
		}
		> span {
			position: relative;
      z-index: 2;
		}
		.wrap span {
			display: inline-block;
		}
	}
}

// -------------------------------------------------- ネガインライン
.c-boxInline {
	display: block;
	padding: 0 .8em;
	color: #0c0c0c;
	.box {
		display: inline;
		padding: .6em 0;
		line-height: 2.8;
		background: #fff;
		box-shadow: -.8em 0 0 #fff, .8em 0 0 #fff;
		&::after {
			position: relative;
			top: -.1em;
			right: auto;
			display: inline-block;
			margin: 0 0 0 .3em;
			vertical-align: middle;
		}
	}
}

// -------------------------------------------------- リンク
.c-link {
	position: relative;
	display: inline-block;
	margin-top: 3em;
	color: #fff;
	&::before {
		position: absolute;
		top: calc(50% - .5em);
		right: 35px;
		width: .8em;
		height: .8em;
		content: "";
		border-top: .15em solid currentcolor;
		border-right: .15em solid currentcolor;
		transition: transform .3s $easeOutCubic;
		transform: rotate(45deg);
	}
	&:hover::before {
		transition-delay: .1s;
		transform: rotate(45deg) translate(3px,-3px);
	}
	svg {
		position: relative;
		top: -.1em;
		display: inline-block;
		width: 80px;
		height: 80px;
		margin-left: -1.2em;
		vertical-align: middle;
		fill: none;
		stroke: #412ea9;
		stroke-dasharray: 222;
		stroke-dashoffset: 444;
		stroke-width: 3;
		clip-path: url(#cLinkMask);
	}
	&.out svg,
	&:not(.out):hover svg {
		transition: stroke-dashoffset .3s $easeOutCubic;
	}
	&.out svg {
		stroke-dashoffset: 0;
	}
	&:not(.out):hover svg {
		stroke-dashoffset: 222;
	}
}
.l-txtContainer:not([style]) { z-index: 1; }
@include UNDER() {
	.l-txtContainer { text-align: right; }
	.l-txtContainer > * { text-align: left; }
	.c-link::before {
		top: calc(50% - 8px);
		width: 12px;
		height: 12px;
		border-top: 2px solid currentcolor;
		border-right: 2px solid currentcolor;
	}
}

// -------------------------------------------------- SUCCESS
.success-list {
	.item {
		position: relative;
		> :not(.img) {
			z-index: 2;
		}
    > .lead {
      z-index: 3;
    }
		@include OVER() {
			padding-top: 48px;
			&:nth-child(2n+1) { padding-left: 224px; }
			&:nth-child(2n) { padding-right: 224px; }
		}
		&:not(:first-child) {
			margin-top: 120px;
			@include UNDER() {
				margin-top: 48px;
			}
		}
	}
	.name {
		position: relative;
		.en {}
		.jp {
			display: block;
			margin-top: 8px;
		}
	}
	.lead {
		position: relative;
		z-index: 1;
		padding: 0 .4em;
		margin-top: 192px;
		.box {
			padding: .3em 0;
			line-height: 2.2;
			box-shadow: -.4em 0 0 #fff, .4em 0 0 #fff;
		}
	}
	.txt {
		@include OVER() {
			max-width: 365px;
			margin-top: 40px;
		}
		@include UNDER() {
			margin-top: 16px;
		}
	}
	.img {
		position: absolute;
		overflow: hidden;
		@include OVER() {
			top: 0;
			right: 72px;
			width: 590px;
			height: 389px;
		}
		@include UNDER() {
			top: 80px;
			right: calc(-48vw / 7.5);
			left: calc(-48vw / 7.5);
			width: auto;
			height: 250px;
			//margin-right: ;
			//margin-left: calc(-48vw / 7.5);
		}
		.shape {
			width: 100%;
			height: 100%;
			@include OVER() {
				> * {
					position: absolute;
					top: 0;
					left: 0;
				}
			}
			.body { z-index: 1; }
			.shadow { transform: translate(104px, 56px); }
			@include UNDER() {
				text-align: center;
				img { width: 100%; }
			}
		}
	}
	.illust {
		position: absolute;
		@include OVER() {
			bottom: 0;
		}
		@include UNDER() {
			display: none;
		}
	}
	@include OVER() {
		.item:nth-child(2n) {
			.img { left: 72px; }
			.illust { right: 0; }
		}
		.item:nth-child(2n+1) {
			.img { right: 72px; }
			.illust { left: 0; }
		}
	}
	.item:nth-child(1) {
		.shape {
			background: #403c77;
		}
		.illust {
			@include OVER() { width: 172px; }
			@include UNDER() { width: 70px; }
		}
	}
	.item:nth-child(2) {
		.shape {
			background: #6b773f;
		}
		.illust {
			@include OVER() { width: 219px; }
			@include UNDER() { width: 89px; }
		}
	}
	.item:nth-child(3) {
		.shape {
			background: #5c2e6b;
		}
		.illust {
			@include OVER() { width: 245px; }
			@include UNDER() { width: 100px; }
		}
	}
}
.success-list .item {
	// transition
	&.in-view  {
		.img { transition: opacity .4s, clip .6s $easeOutCubic; }
		@include UNDER() {
			.img { transition-property: opacity, clip-path; }
		}
		.name,
		.lead,
		.txt,
		.illust { transition: opacity .6s, transform .6s $easeOutCubic; }
		.illust { transition-delay: .2s; }
		.name { transition-delay: .4s; }
		.lead { transition-delay: .5s; }
		.txt  { transition-delay: .6s; }
	}
	// 出現前
	&.js-inview:not(.in-view) {
		@include OVER() {
			.img { clip: rect(0,0,490px,0); }
		}
		@include UNDER() {
			.img { clip-path: polygon(100% 0,100% 0,100% 100%, 100% 100%); }
		}
		.name,
		.lead,
		.txt,
		.illust {
			opacity: 0;
			transform: translateY(32px);
		}
	}
	// 出現後
	&.js-inview.in-view {
		@include OVER() {
			.img { clip: rect(0,590px,490px,0); }
		}
		@include UNDER() {
			.img { clip-path: polygon(0 0,100% 0,100% 100%, 0 100%); }
		}
	}
}

// -------------------------------------------------- REASON
.reason-list {
	@include OVER() {
		display: flex;
		justify-content: space-between;
		.item {
			flex-basis: calc(33.333333% - 24px);
			max-width: calc(33.333333% - 24px);
		}
	}
	.item {
		padding: 40px 24px;
		background: #170f3c;
		@include UNDER() {
			&:not(:first-child) { margin-top: 24px; }
		}
		@include UNDER(374px) {
			padding: 24px 16px;
		}
	}
	.ico {
		max-width: 150px;
		margin: 0 auto;
	}
	.num {
		display: block;
		margin-top: 24px;
		color: #523fce;
	}
	.title {
		padding: 0 .4em;
		margin-top: 24px;
		@include UNDER() {
			margin-top: 16px;
		}
		.box {
			padding: .3em 0;
			line-height: 2.2;
			box-shadow: -.4em 0 0 #fff, .4em 0 0 #fff;
		}
	}
	.txt {
		margin-top: 16px;
	}
}
.reason-section {
	// transition
	&.in-view  {
		.reason-list .item {
			transition: opacity .6s, transform .6s $easeOutCubic;
			&:nth-child(3n+1) { transition-delay: .5s; }
			&:nth-child(3n+2) { transition-delay: .6s; }
			&:nth-child(3n+3) { transition-delay: .7s; }
		}
	}
	// 出現前
	&.js-inview:not(.in-view) {
		.reason-list .item {
			opacity: 0;
			transform: translateY(32px);
		}
	}
}

// -------------------------------------------------- 仕事一覧
.works-list {
	.item {
		padding: 32px 24px;
		background: #170f3c;
		&:not(:first-child) {
			margin-top: 40px;
		}
	}
	.img {
		margin: 0 auto;
		@include OVER() { max-width: 224px; }
		@include UNDER() { max-width: 160px; }
	}
	@include OVER() {
		.item {
			display: flex;
			justify-content: space-between;
			padding: 48px 64px;
		}
		.img {
			flex-basis: 225px;
		}
	}
	.txt-wrap {
		@include OVER() { flex-basis: calc(100% - 300px); }
		@include UNDER() { margin-top: 24px; }
		.num {
			> span {
				margin-right: 8px;
				color: #523fce;
			}
		}
		.title {
			padding: 0 .4em;
			margin-top: 24px;
			.box {
				padding: .3em 0;
				box-shadow: -.4em 0 0 #fff, .4em 0 0 #fff;
			}
		}
		.txt {
			margin-top: 24px;
		}
	}
}
body:not(.paging) .interview-section.js-inview {
	&.in-view {
		.interview-list .item {
			.img, .box { transition: opacity .6s, transform .6s $easeOutCubic; }
			// timing
			@for $value from 1 through 3 {
				&:nth-child(3n+#{$value}) {
					.img { transition-delay: .4s + .05s * $value; }
					.box:nth-child(1) { transition-delay: .45s + .08s * $value; }
					.box:nth-child(2) { transition-delay: .5s + .08s * $value; }
				}
			}
		}
	}
	&:not(.in-view) {
		.interview-list .item .img,
		.interview-list .item .box {
			opacity: 0;
			transform: translateY(16px);
		}
	}
}
body:not(.paging) .blog-section.js-inview {
	&.in-view {
		.blog-list .item {
			.img, .title, .excerpt, .date  { transition: opacity .6s, transform .6s $easeOutCubic; }
			// timing
			@for $value from 1 through 2 {
				&:nth-child(2n+#{$value}) {
					.img  { transition-delay: .4s + .08s * $value; }
					.title  { transition-delay: .45s + .08s * $value; }
					.excerpt  { transition-delay: .5s + .08s * $value; }
					.date  { transition-delay: .55s + .08s * $value; }
				}
			}
		}
	}
	&:not(.in-view) {
		.blog-list .item .img,
		.blog-list .item .title,
		.blog-list .item .excerpt,
		.blog-list .item .date {
			opacity: 0;
			transform: translateY(16px);
		}
	}
}

// -------------------------------------------------- INTERVIEW
.interview-list {
	@include OVER() {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.item {
			flex-basis: calc(100% / 3 - 112px / 3);
			&:nth-child(n+4) {
				margin-top: 64px;
			}
		}
	}
	@include UNDER() {
		.item:not(:first-child) {
			margin-top: calc(40vw / 7.5);
		}
	}
	.clip {
		display: block;
		background: #00b3e9;
		clip-path: polygon(0 7%, 53% 7%, 59% 0, 100% 0, 100% 93%, 47% 93%, 41% 100%, 0 100%);
	}
	.img img {
		max-width: 100% !important;
		height: auto !important;
		vertical-align: middle;
		opacity: .3;
		transition: transform .6s $easeOutCubic, opacity .6s $easeOutCubic;
	}
	a {
		&:hover .img img {
			opacity: 1;
			transform: scale(1.1);
		}
		@include UNDER() {
			display: flex;
			justify-content: space-between;
			&:not(:first-child) {
				margin-top: calc(40vw / 7.5);
			}
			.img {
				flex-basis: 27%;
			}
			.txt {
				flex-basis: 73%;
				padding-left: calc(48vw / 7.5);
			}
		}
	}
	dl {
		display: flex;
		flex-direction: column;
		align-items: flex-start;
    @include OVER() { margin-top: 2em;}
	}
	dd {
		color: #686868;
	}
}
.interview-section {
	@include UNDER() {
		text-align: right;
		> * { text-align: left; }
		.c-topTitle { margin-bottom: calc(112vw / 7.5); }
	}
	@include OVER() {
		padding-bottom: 100px;
		text-align: right;
		> * { text-align: left; }
	}
}

// -------------------------------------------------- BLOG
.blog-list {
	@include OVER() {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		.item {
			flex-basis: calc(50% - 56px / 2);
			&:nth-child(n+3) {
				margin-top: 64px;
			}
		}
	}
	.clip {
		display: block;
		background: #00b3e9;
		clip-path: polygon(0 7%, 34% 7%, 38% 0, 100% 0, 100% 93%, 66% 93%, 62% 100%, 0 100%);
	}
	.img img {
		max-width: 100%;
		height: auto;
		vertical-align: middle;
		opacity: .3;
		transition: transform .6s $easeOutCubic, opacity .6s $easeOutCubic;
	}
	a:hover .img img {
		opacity: 1;
		transform: scale(1.1);
	}
	.title {
		margin: 2em 0 1.6em;
		.box { position: relative; }
	}
	.excerpt { color: #fff; }
	.date {
		margin-top: 1em;
		color: #5bced1;
	}
}
@include OVER() {
	.blog-section {
		padding-top: 100px;
		text-align: right;
		&.p-blog {
			padding-top: 0;
		}
	}
	.blog-section > * { text-align: left; }
}
@include UNDER() {
	.blog-section { text-align: right; }
	.blog-section > * { text-align: left; }
	.blog-section .c-topTitle {
		margin-bottom: calc(112vw / 7.5);
	}
	.blog-list .item:not(:first-child) {
		margin-top: calc(80vw / 7.5);
	}
	.blog-list .title { margin: 1.2em 0; }
}

/* :::::: for IE/Edge :::::: */
.l-container:not(.isIE):not(.isEdge) {
	.interview-list .clip svg { display: none; }
	.blog-list .clip svg { display: none; }
}
.l-container.isIE .interview-list,
.l-container.isEdge .interview-list,
.l-container.isIE .blog-list,
.l-container.isEdge .blog-list {
	.clip {
		position: relative;
		width: 100%;
		height: 0;
		padding-bottom: 99.646643%;
		background: none;
		clip-path: none;
	}
	.clip::before {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 1;
		width: 100%;
		height: 100%;
		content: "";
		opacity: .7;
	}
	.clip img[src] { display: none; }
	.clip svg {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		vertical-align: middle;
	}
	.clip svg image {
		height: auto;
		opacity: 1;
		transition: all .3s $easeOutCubic;
	}
}
.l-container.isIE .interview-list,
.l-container.isEdge .interview-list {
	.clip {
		padding-bottom: 99.646643%;
	}
	.clip::before {
		background: url(../img/top/shape_interview.svg) center / cover no-repeat;
	}
	.clip svg image {
		width: 564px;
		clip-path: url(#interviewClipPath);
	}
}
.l-container.isIE .blog-list,
.l-container.isEdge .blog-list {
	.clip {
		padding-bottom: 56.637168%;
	}
	.clip::before {
		background: url(../img/top/shape_blog.svg) center / cover no-repeat;
	}
	.clip svg image {
		width: 904px;
		clip-path: url(#blogClipPath);
	}
}
// pagenavi
.wp-pagenavi {
	display: flex;
	justify-content: center;
	margin-top: 64px;
	a, span {
		width: 40px;
		height: 40px;
		overflow: hidden;
		line-height: 40px;
		color: #000;
		text-align: center;
		background: #fff;
		&.first,
		&.last {
			position: relative;
			color: transparent;
			background: none;
			&::before,
			&::after {
				position: absolute;
				top: calc(50% - 6px);
				left: calc(50% - 4px);
				display: block;
				width: 7px;
				height: 12px;
				content: "";
			}
		}
		&.first::before { left: calc(50% - 8px); }
		&.last::before { left: 50%; }
		&.previouspostslink,
		&.nextpostslink {
			position: relative;
			color: transparent;
			background: #553dcc;
			&::after {
				position: absolute;
				top: calc(50% - 6px);
				left: calc(50% - 4px);
				width: 7px;
				height: 12px;
				content: "";
			}
		}
		&.first::before,
		&.first::after,
		&.previouspostslink::after {
			background: url(/img/common/pagenavi_arrow_prev.svg) center / cover no-repeat;
		}
		&.last::before,
		&.last::after,
		&.nextpostslink::after {
			background: url(/img/common/pagenavi_arrow_next.svg) center / cover no-repeat;
		}
		&.current {
			color: #fff;
			background: none;
		}
		@include OVER(375px) {
			&:not(:first-child) {
				margin-left: 16px;
			}
		}
		@include UNDER(374px) {
			&:not(:first-child) {
				margin-left: 8px;
			}
		}
	}
}

// -------------------------------------------------- WELFARE
.welfare-list {
	@include OVER() {
		display: flex;
		flex-wrap: wrap;
		.item {
			flex-basis: calc(33.333333% - 40px);
      max-width: calc(33.333333% - 40px); //IE11
			padding: 64px 24px 56px;
		}
	}
	.item {
		background: #170f3c;
		@include OVER() {
			padding: 56px 24px 48px;
			&:nth-child(n+4) {
				margin-top: 60px;
			}
			&:nth-child(3n),
			&:nth-child(3n+2) {
				margin-left: 60px;
			}
		}
		@include UNDER() {
			padding: 56px 24px 48px;
			&:not(:first-child) {
				margin-top: 24px;
			}
		}
	}
	.img {
		display: flex;
		align-items: center;
		justify-content: space-between;
		.ico {
			flex-basis: 58px;
			width: 58px;
			height: 58px;
		}
		.title {
			flex-basis: calc(100% - 64px);
			.box {
				padding: .3em 0;
				box-shadow: -.4em 0 0 #fff, .4em 0 0 #fff;
			}
		}
	}
	.txt {
		margin-top: 16px;
	}
}

// -------------------------------------------------- SECURITY
.security-section {
	.lead { margin-bottom: 32px; }
}
.security-list {
	list-style: none;
	.item {
		counter-increment: "num";
		&:not(:first-child) {
			margin-top: 32px;
		}
		&::before {
			display: block;
			width: 1.8em;
			height: 1.8em;
			margin-bottom: 16px;
			color: #000;
			text-align: center;
			content: counter(num);
			background: #fff;
		}
		> span {
			display: block;
			+ span {
				margin-top: 1em;
			}
		}
		a {
			color: inherit;
			text-decoration: underline;
			&:hover {
				text-decoration: inherit;
			}
		}
	}
}