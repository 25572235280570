@charset "utf-8";

$easeInCubic: cubic-bezier(0.55, 0.055, 0.675, 0.19);
$easeOutCubic: cubic-bezier(0.215, 0.61, 0.355, 1);
$easeInOutCubic: cubic-bezier(0.645, 0.045, 0.355, 1);
$easeInExpo: cubic-bezier(0.95, 0.05, 0.795, 0.035);
$easeOutExpo: cubic-bezier(0.19, 1, 0.22, 1);
$easeInOutExpo: cubic-bezier(1, 0, 0, 1);

//@include font-JP;
@mixin font-JP {
  font-family: "Noto Sans Japanese", sans-serif;
  font-weight: 200;
}

//@include font-EN;
@mixin font-EN {
  font-family: 'Prompt', sans-serif;
  font-style: normal;
}

// @include UNDER(959px) {}
@mixin UNDER($value: 959px) {
	@media screen and (max-width: $value) {
		@content;
	}
}

// @include OVER(960px) {}
@mixin OVER($value: 960px) {
	@media print, (min-width: $value) {
		@content;
	}
}
