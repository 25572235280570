@charset "utf-8";

.l-kv {
	z-index: 1;
	height: 100vh;
	padding: 100px 5vw 0;
	@include OVER() {
		position: relative;
		display: flex;
		flex-direction: column;
		justify-content: center;
		margin-top: -104px;
		overflow: hidden;
	}
	@include UNDER() {
		position: relative;
		overflow: hidden;
	}
	@include OVER() {
		padding: 100px 13vw 0;
	}
}

.kv-visual,
.kv-visual img,
.kv-visual svg {
	position: absolute;
	top: 0;
	left: 0;
}
@include OVER() {
	.l-container:not(.width) {
		.kv-visual {
			width: calc(171.4285714vh + 4vw);
			height: 100vh;
		}
		.kv-visual {
			left:calc(50% - 83.714286vh);
		}
	}
	.l-container.width {
		.kv-visual {
			width: 104vw;
			height: 60.666666vw;
		}
		.kv-visual {
			top: calc(50% - 30.333333vw);
			left: -2vw;
		}
	}
}
.kv-visual {
	z-index: -1;
	img, svg {
		width: 100%;
		height: 100%;
	}
	.layer5 { opacity: .8; }
	svg image { transform-origin: 50% 50%; }
	@include UNDER() {
		&.v-sp {
			left: 0 !important;
			width: 100% !important;
			height: 100vh !important;
			background: url(../img/top/visual_sp.jpg) center / cover no-repeat;
		}
	}
}
.l-container.active {
	.kv-visual {
		svg image {
			animation: effect 20s infinite linear;
		}
		.layer1 image {
			animation-duration: 13s;
			animation-direction: alternate;
		}
		.layer2 image {
			animation-duration: 19s;
			animation-timing-function: ease;
		}
		.layer3 image {
			animation-duration: 17s;
			animation-direction: reverse-alternate;
		}
		.layer4 image { 
			animation-name: effect2;
			animation-duration: 15s;
			animation-direction: alternate;
		}
	}
}
@keyframes effect {
	from { transform: none; }
	to { transform: rotate(360deg); }
}
@keyframes effect2 {
	from { transform: none; }
	to { transform: translateY(50%); }
}

// -------------------------------------------------- テキスト
.kv-title {
	margin-bottom: .2em;
	@include UNDER() {
		&.v-pc { display: none; }
	}
	@include OVER() {
		&.v-sp { display: none; }
	}
}
.kv-copy {
	margin-bottom: 1.2em;
}
.kv-description {
	transform: scale(.8);
	transform-origin: 0 0;
	@include OVER() { margin-bottom: 16px; }
	@include UNDER() { display: none; }
}
.c-scroll {
	position: absolute;
	right: calc(2.4% + .3em);
	bottom: 1em;
	transform: rotate(90deg);
	transform-origin: 100% 0%;
	@include UNDER() {
		right: calc(6.4% - .3em);
		bottom: calc(48vw / 7.5);
	}
	&::after {
		position: relative;
		top: -.1em;
		display: inline-block;
		width: 9em;
		margin-left: 1em;
		vertical-align: middle;
		content: "";
		border-top: 1px solid currentcolor;
	}
}
@keyframes scroll-border {
	0%, 1% { transform: scaleX(0); transform-origin: 0 50%; }
	16% { transform: none; transform-origin: 0 50%; }
	84% { transform: none; transform-origin: 100% 50%; }
	99%, 100% { transform: scaleX(0); transform-origin: 100% 50%; }
}

// -------------------------------------------------- キラキラ
.kv-sparkle {
	@include OVER() {
		position: absolute;
		z-index: -1;
		li:nth-child(1) {
			top: -55px;
			left: calc(340vw / 13.66);
			width: 205px;
			height: 185px;
		}
		li:nth-child(2) {
			top: calc(40vh / 7.34);
			left: calc(510vw / 13.66);
			width: 141px;
			height: 143px;
		}
		li:nth-child(3) {
			top: calc(140vh / 7.34);
			left: calc(510vw / 13.66);
			width: 103px;
			height: 106px;
		}
		li:nth-child(4) {
			top: calc(170vh / 7.34);
			left: calc(760vw / 13.66);
			width: 139px;
			height: 144px;
		}
		li:nth-child(5) {
			top: calc(50vh / 7.34);
			left: calc(820vw / 13.66);
			width: 263px;
			height: 237px;
		}
		li img { max-width: 100%; }
	}
	@include UNDER() { display: none; }
}

// -------------------------------------------------- パーティクル
.kv-particle {
	position: absolute;
	bottom: -3vh;
	left: 0;
	z-index: -1;
	width: 100%;
	li, span {
		position: absolute;
	}
	svg {
		width: 100%;
		height: 100%;
		fill: currentcolor;
	}
}
// 浮上
@keyframes emerge1 {
	0% { color: #1eebd7; opacity: 1; transform: none; }
	40% { color: #412ea9; opacity: 1; }
	100% { color: #412ea9; opacity: 0; transform: translateY(-420px); }
}
@keyframes emerge2 {
	0% { color: #1eebd7; opacity: 1; transform: none; }
	40% { color: #412ea9; opacity: 1; }
	100% { color: #412ea9; opacity: 0; transform: translateY(-560px); }
}
@keyframes emerge3 {
	0% { color: #1eebd7; opacity: 1; transform: none; }
	40% { color: #412ea9; opacity: 1; }
	100% { color: #412ea9; opacity: 0; transform: translateY(-700px); }
}
// 揺れ
@keyframes sway1 {
	from { transform: none; }
	to { transform: translateX(1vw) rotateY(360deg); }
}
@keyframes sway2 {
	from { transform: none; }
	to { transform: translateX(2vw) rotateY(240deg); }
}
@keyframes sway3 {
	from { transform: none; }
	to { transform: translateX(3vw) rotateY(180deg); }
}
@keyframes sway4 {
	from { transform: none; }
	to { transform: translateX(4vw) rotateY(240deg); }
}
@keyframes sway5 {
	from { transform: none; }
	to { transform: translateX(5vw) rotateY(360deg); }
}
// 回転
@keyframes rotateZ {
	from { transform: none; }
	to { transform: rotateZ(360deg); }
}
/* forIE */
.l-container.isIE,
.l-container.isEdge {
	@keyframes sway1 {
		from { transform: none; }
		to { transform: translateX(7px) rotateY(360deg); }
	}
	@keyframes sway2 {
		from { transform: none; }
		to { transform: translateX(14px) rotateY(240deg); }
	}
	@keyframes sway3 {
		from { transform: none; }
		to { transform: translateX(21px) rotateY(180deg); }
	}
	@keyframes sway4 {
		from { transform: none; }
		to { transform: translateX(28px) rotateY(240deg); }
	}
	@keyframes sway5 {
		from { transform: none; }
		to { transform: translateX(35px) rotateY(360deg); }
	}
}

.l.kv {
	.l-cover,
	.kv-title,
	.kv-particle li {
		transform-style: preserve-3d;
	}
}

/* -------------------------------------------------- オープニング
	---------- 0ms:
	.l-container.loading
	---------- 200ms:
	.l-container.loading.anima (anima 付く)
	---------- 400ms:
	.l-container.loading.anima.cover (cover 付く)
		.l-cover が全面覆い始める
	
	---------- 1000ms:
	.l-container.anima.cover (loading 外す)
		.l-cover が動き出した段階で、ローダーがフェードアウト
	
	---------- .l-cover の transitionend のタイミング:
	.l-container.cover.anima.active (active 付く)
		.l-cover が全面覆ったら今度は .l-cover を左端へスライド
		タイトルとかもフェードイン
	
	---------- .c-scroll の transitionend のタイミング:
	.l-container.active (cover と anima 外す)
		最後に .c-scroll がフェードイン完了したら .cover と .anima 外す
	
*/
// アニメーション・タイミング
.l-container.anima {
	a { pointer-events: none; }
	.l-cover {
		transition: transform .8s $easeOutCubic;
	}
	.l-kv {
		.kv-visual {
			transition: opacity 0s 1.2s, transform 1s $easeOutCubic;
		}
		.tx-mask {
			span { transition: transform .6s $easeOutCubic; }
			&:nth-child(1) span { transition-delay: .2s; }
			&:nth-child(2) span { transition-delay: .4s; }
			&:nth-child(3) span { transition-delay: .6s; }
		}
		.kv-copy .tx-mask {
			span { transition-delay: .8s; }
		}
		.kv-description .tx-mask {
			&:nth-child(1) span { transition-delay: 1s; }
			&:nth-child(2) span { transition-delay: 1.1s; }
			&:nth-child(3) span { transition-delay: 1.2s; }
		}
	}
	.l-header,
	.l-kv .c-scroll,
	.kv-sparkle li {
		transition: opacity .6s .4s $easeOutCubic, transform .6s .4s $easeOutCubic;
	}
	.l-header {
		transition-duration: .6s !important;
		transition-delay: 1s !important;
	}
	.l-kv .c-scroll { transition-delay: 1.2s; }
	.kv-sparkle li {
		transition-delay: 0s;
		transition-duration: 1s;
	}
}
@include OVER() {
	.l-kv .l-cover {
		top: 0;
		//right: calc(-58vh / 2);
		left: calc(-100vw - 29vh);
		width: calc(100% + 58vh + 140px);
		height: 100vh;
		transform: translateX(256vw);
	}
	.l-kv .l-cover::after {
		transform: skewX(-30deg);
	}
	.l-container.cover {
		.l-kv .l-cover { transform: translateX(100vw); }
	}
	.l-container.active {
		.l-kv .l-cover { transform: none; }
	}
}
// オープン前
.l-kv .tx-mask,
.l-kv .tx-mask span { display: block; font-style: normal; }
.l-kv .tx-mask { overflow: hidden; }
.l-container:not(.cover):not(.active) {
	.kv-visual { opacity: 0; }
}
.l-container:not(.active) {
	position: fixed;
	width: 100%;
	height: 100%;
	.l-header {
		pointer-events: none;
		opacity: 0;
		transform: translateY(-16px);
	}
	.kv-visual {
		transform: translateY(4vw);
	}
	.l-kv .tx-mask span {
		transform: translateY(100%);
	}
	.l-kv .c-scroll {
		opacity: 0;
		transform: translateX(16px) rotate(90deg);
	}
	@include OVER() {
		.kv-visual { transform: translateX(-2vw); }
		.kv-sparkle li {
			opacity: 0;
			transform: translateX(-2vw);
		}
		&.isIE .kv-visual,
		&.isIE .kv-sparkle li {
			transform: translateX(-32px);
		}
	}
}
// カバー
.l-container.cover {
	&.anima .l-cover {
		transition: transform 1s $easeInExpo;
	}
	@include UNDER() {
		.l-kv .l-cover {
			transform: translateY(100vh);
		}
	}
}
// オープン後
.l-container.active {
	&.anima .l-cover {
		transition: transform 1.2s cubic-bezier(0.075, 0.82, 0.165, 1);
	}
	.c-scroll::after {
		animation: scroll-border 4s -2s infinite $easeOutCubic;
		will-change: animation;
	}
	.l-kv.paused .c-scroll::after {
		animation-play-state: paused;
	}
	.kv-particle li,
	.kv-particle span,
	.kv-particle svg {
		will-change: animation;
	}
	.kv-particle li {
		position: absolute;
		animation: emerge1 10s infinite $easeOutCubic; // 色替浮上
		span { animation: sway1 4s alternate infinite; } // 揺れ
		svg { animation: rotateZ 8s infinite linear; } // 回転
		&:nth-child(3n+1) {
			animation-name: emerge2;
			span { animation-direction: alternate-reverse; }
		}
		&:nth-child(3n+2) {
			animation-name: emerge3;
			svg { animation-direction: reverse; }
		}
		&:nth-child(5n+1) {
			animation-delay: .5s;
			span { animation-name: sway2; }
			svg { animation-duration: 3s; }
		}
		&:nth-child(5n+2) {
			animation-delay: 1s;
			span { animation-name: sway3; }
			svg { animation-duration: 4s; }
		}
		&:nth-child(5n+3) {
			animation-delay: 2s;
			span { animation-name: sway4; }
			svg { animation-duration: 5s; }
		}
		&:nth-child(5n+4) {
			animation-delay: .1s;
			span { animation-name: sway5; }
			svg { animation-duration: 6s; }
		}
	}
	.l-kv.paused .kv-particle li {
		display: none;
	}
	@include UNDER() {
		.l-main .l-cover {
			transform: none;
		}
		.l-kv .l-cover {
			transform: translateY(50vh) translateY(-78vw);
			transform: translateY(calc(50vh - 78vw));
			backface-visibility: hidden;
			will-change: transform;
		}
	}
}