@charset "utf-8";

@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 200;
	src:url("/fonts/NotoSansCJKjp-DemiLight.woff") format("woff"),
		url("/fonts/NotoSansCJKjp-DemiLight.otf") format("opentype");
}
@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 400;
	src:url("/fonts/NotoSansCJKjp-Regular.woff") format("woff"),
		url("/fonts/NotoSansCJKjp-Regular.otf") format("opentype");
}
@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 500;
	src:url("/fonts/NotoSansCJKjp-Medium.woff") format("woff"),
		url("/fonts/NotoSansCJKjp-Medium.otf") format("opentype");
}
@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 700;
	src:url("/fonts/NotoSansCJKjp-Bold.woff") format("woff"),
		url("/fonts/NotoSansCJKjp-Bold.otf") format("opentype");
}
@font-face {
	font-family: "Noto Sans Japanese";
	font-style: normal;
	font-weight: 900;
	src:url("/fonts/NotoSansCJKjp-Black.woff") format("woff"),
		url("/fonts/NotoSansCJKjp-Black.otf") format("opentype");
}

.ja { @include font-JP; }
.en { @include font-EN; }

// KEY VISUAL
.kv-title,
.kv-description {
	@include font-EN;
	font-weight: 700;
}
.kv-title {
	font-size: 96px;
	font-weight: 900;
	line-height: 1.2;
	text-transform: uppercase;
	letter-spacing: .2em;
	@include OVER(1366px) { font-size: calc(96vw / 13.66); }
	@include UNDER() { font-size: 48px; }
}
.kv-copy {
	@include font-JP;
	font-size: 18px;
	font-weight: 400;
	letter-spacing: .5em;
	@include OVER(1366px) { font-size: calc(18vw / 13.66); }
	@include UNDER() { font-size: 16px; }
}
.kv-description {
	font-size: 10px;
	font-weight: 200;
	line-height: 3;
	text-transform: uppercase;
	letter-spacing: .12em;
	@include OVER(1366px) { font-size: calc(10vw / 13.66); }
}
.c-scroll {
	font-size: 10px;
	font-weight: 400;
	line-height: 1;
	text-transform: uppercase;
	letter-spacing: .7em;
	white-space: nowrap;
	@include UNDER() { font-size: 10px; }
}

// BUTTON
.c-btn {
	.en {
		@include font-EN;
		font-size: 28px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: .24em;
	}
	.jp {
		font-size: 10px;
		font-weight: 400;
		letter-spacing: .16em;
	}
  .large {
		font-size: 16px;
		font-weight: 600;
		text-transform: uppercase;
		letter-spacing: .12em;
	}
}

// TOGGLE MENU
.l-toggle {
	.l-gnav {
		.en {
			@include font-EN;
			font-size: 24px;
			font-weight: 600;
			text-transform: uppercase;
			letter-spacing: .24em;
		}
		.jp {
			font-size: 10px;
			font-weight: 400;
			letter-spacing: .16em;
		}
	}
	.entry-btn {
		.en {
			font-size: 20px;
		}
	}
}

// GNAV
@include OVER() {
	.gnav-list {
		font-size: 11px;
		letter-spacing: .24em;
		.c-btn {
			a {
				font-weight: 600;
				line-height: 32px;
				text-transform: uppercase;
			}
			&.ef {
				.en, a::before {
					font-size: inherit;
				}
				a::before {
					@include font-EN;
				}
			}
		}
	}
}

// TITLE
.c-topTitle {
	@include font-EN;
	text-transform: uppercase;
	> * {
		line-height: 1.2;
	}
	sup {
		@include font-EN;
		font-size: 8px;
		font-weight: 300;
		text-transform: uppercase;
		white-space: nowrap;
		@include UNDER() { font-size: 7px; }
	}
	b {
    font-size: 66px;
		line-height: 1.38;
		letter-spacing: .24em;
    @include UNDER() { font-size: 42px; }
    @include OVER() {
      #conceptPage section:not(.message-intro) & {
        font-size: 56px;
      }
    }
	}
	.ja {
    font-size: 16px;
		font-weight: 500;
		letter-spacing: .48em;
    text-transform: none;
		@include UNDER() { font-size: 16px; }
    @include OVER() {
      #conceptPage section:not(.message-intro) & {
        font-size: 15px;
      }
    }
	}
}
.c-section .c-topTitle {
	b {
		font-size: 40px;
		@include UNDER() { font-size: 42px; }
	}
	.ja { font-size: 16px; }
}

// LEAD
.c-pageLead {
	font-size: 12px;
	line-height: 2.2;
	letter-spacing: .08em;
}

// LINK
.c-link {
	font-size: 14px;
	line-height: 1;
	@include UNDER() { font-size: 15px; }
}

// -ENTRY
.l-entry {
	.c-topTitle {
		font-size: 40px;
		font-weight: 600;
		letter-spacing: .24em;
		@include UNDER() { font-size: 30px; }
	}
	.c-boxTxt {
		font-size: 22px;
		font-weight: 500;
		@include UNDER() { font-size: 14px; }
	}
}

// INTRO
.l-intro {
	.lead {
		font-size: 16px;
		line-height: 2.5;
		letter-spacing: .32em;
		@include UNDER() {
			font-size: 16px;
			line-height: 2.3;
		}
	}
	.copy {
		font-size: 42px;
		font-weight: 700;
		letter-spacing: .24em;
		.box { font-weight: inherit; }
		@include OVER() { line-height: 1; }
		@include UNDER() { font-size: 26px; }
	}
	.txt {
		font-size: 14px;
		line-height: 3;
		letter-spacing: .16em;
		* { letter-spacing: inherit; }
		@include UNDER() {
			font-size: 13px;
			line-height: 2.3;
		}
	}
}

// SUCCESS
.success-list {
	.name {
		.en {
			font-size: 30px;
			line-height: 1.5;
			text-transform: uppercase;
			letter-spacing: .24em;
			@include UNDER() { font-size: 26px; }
		}
		.jp {
			font-size: 17px;
			@include UNDER() { font-size: 14px; }
		}
	}
	.lead {
		font-size: 22px;
		letter-spacing: .24em;
		@include UNDER() { font-size: 17px; }
	}
	.txt {
		font-size: 12px;
		line-height: 2.2;
		letter-spacing: .16em;
	}
}

// REASON
.reason-list {
	.num {
		margin-top: 24px;
		font-size: 18px;
		@include font-EN;
		font-weight: 600;
		text-transform: uppercase;
		@include UNDER() { font-size: 19px; }
	}
	.title {
		font-size: 17px;
	}
	.txt {
		font-size: 12px;
		line-height: 2.2;
		letter-spacing: .16em;
	}
}

// WORKS
.works-list {
	.txt-wrap {
		.num {
			font-size: 30px;
			font-weight: 600;
      color: #523fce;
			text-transform: uppercase;
			letter-spacing: .24em;
			@include font-EN;
			@include UNDER() { font-size: 19px; }
		}
		.title {
			font-size: 17px;
			.box { line-height: 2.2; }
		}
		.txt {
			font-size: 13px;
			line-height: 2.2;
			letter-spacing: .08em;
		}
	}
}

// INTERVIEW
.interview-list {
	letter-spacing: .24em;
	dt {
		font-size: 16px;
		font-weight: 600;
		@include UNDER() { font-size: 16px; }
		
	}
	dd {
		font-size: 11px;
		@include UNDER() { font-size: 10px; }
	}
	.c-link {
		@include UNDER() { letter-spacing: .1em; }
	}
}

// BLOG
.blog-list {
	letter-spacing: .24em;
	.title {
		font-size: 16px;
		font-weight: 700;
		@include UNDER() { font-size: 16px; }
	}
	.excerpt {
		font-size: 13px;
		line-height: 2.3;
		letter-spacing: .1em;
		@include UNDER() { font-size: 14px; }
	}
	.date {
    @include font-EN;
		font-size: 11px;
	}
}
.wp-pagenavi {
	@include font-EN;
	font-weight: 900;
}

// WELFARE
.welfare-list {
	.title {
		font-size: 15px;
		.box { line-height: 2.2; }
	}
	.txt {
		font-size: 12px;
		line-height: 2.2;
		letter-spacing: .16em;
		@include UNDER() { font-size: 13px; }
	}
}

// SECURITY
.security-section {
	font-size: 12px;
	font-weight: 500;
	line-height: 2.4;
	letter-spacing: .16em;
	.security-list .item::before {
		font-size: 17px;
		font-weight: 600;
		line-height: 1.8;
		@include font-EN;
	}
}

// FOOTER
.l-footer {
	font-size: 11px;
	.copyright {
		font-size: inherit;
	}
}