@charset "utf-8";

/*
	＃ページ移動時の演出なのでページ最初のセクションのみここで対応
	（次セクション以降は in-view.js で対応）
	
	---------- pjax:send
	0: body.out
	> body.out:not(.send): clip-path 仕込む
	
	60: body.out.paging.send (paging と send 付く)
	> body.out.send: clip-path で 0.3 秒かけて消す
	
	360: .l-container.loading (l-container に loading 付く) 演出上特に影響なし
	
	---------- pjax:complete
	0: body.paging.send.in (out 消して in 付く)
	> body.in.send: 出現前の状態を仕込む
	
	0: body#pageID (該当ページの ID 付く) 演出上特に影響なし
	
	---------- pjax:success
	0: .l-container (loading 消える) 演出上特に影響なし
	
	60: body.in.paging (send 消える)
	> body.in:not(.send): 出現開始
	
	1200: body (in と paging 消える)
*/

// -------------------------------------------------- .paging がついてる間は transition
body.paging {
	a { pointer-events: none; }
	&.out .l-background {
		transition: background-position .3s $easeOutCubic;
	}
	
	.l-header .inner,
	.l-main .l-cover {
		transition: opacity .3s, transform .3s $easeOutCubic;
	}
	
	// 出るときの transition
	&.out {
		// 共通
		.l-footer .pagetop,
		.l-footer .inner {
			transition: opacity .3s, transform .3s $easeOutCubic;
		}
		// トップページ
		.l-kv {
			transition: opacity .3s;
		}
		.l-kv .l-cover {
			transition: transform .3s $easeOutCubic;
		}
		.kv-title .tx-mask,
		.kv-copy .tx-mask,
		.kv-description .tx-mask,
		.l-kv .c-scroll,
		.works-intro .txt,
		.interview-list .img,
		.interview-list .box,
		.blog-list .img,
		.blog-list .title,
		.blog-list .excerpt,
		.blog-list .date,
		.c-link {
			transition: opacity .3s, clip-path .3s $easeOutCubic;
		}
		// 下層
		.c-topTitle .wrap > span,
		.c-topTitle::after,
		.l-intro .lead .wrap,
		.l-intro .copy .box,
		.l-intro .txt .wrap,
		.c-boxTxt .box,
		.l-entry .c-topTitle .wrap,
		.l-entry .entry-btn,
		.l-entry .entry-btn + .entry-btn,
		.message-intro .l-visual,
		.success-list .name,
		.success-list .box,
		.success-list .txt,
		.success-list .img,
		.success-list .illust,
		.reason-list .item,
		.works-intro .l-visual,
		.works-list .item,
		.welfare-list .item,
		.wp-pagenavi,
		.c-pageLead,
		.p-error {
			transition: opacity .3s, clip-path .3s $easeOutCubic !important;
		}
		.l-entry::before {
			transition: opacity .3s;
		}
	}
	
	// 入るときの transition (ページの上の方だけ)
	&.in:not(.send) {
		// ページタイトル
		.l-pageTitle {
			.wrap > span { transition: opacity .4s, transform .4s $easeOutCubic; }
			&::after { transition: opacity .3s, height .3s $easeOutCubic; }
			// timing
			.wrap:nth-child(1) > span {
				transition-delay: .3s;
				transition-duration: .5s;
			}
			.wrap:nth-child(2) > span { transition-delay: .45s; }
			&::after { transition-delay: .6s; }
		}
		.works-intro .l-visual {
			transition: opacity .6s, transform .6s $easeOutCubic;
			transition-delay: .1s;
		}
		// 一覧 .paging でアニメするのは下層だけ (トップは in-view で）
		.l-contents {
			.works-list .item,
			.welfare-list .item {
				transition: opacity .6s, transform .6s $easeOutCubic;
				// timing
				@for $value from 1 through 3 {
					&:nth-child(3n+#{$value}) { transition-delay: .6s + .08s * $value; }
				}
			}
			.interview-list .item {
				.img, .box { transition: opacity .6s, transform .6s $easeOutCubic; }
				// timing
				@for $value from 1 through 3 {
					&:nth-child(3n+#{$value}) {
						.img { transition-delay: .6s + .05s * $value; }
						.box:nth-child(1) { transition-delay: .65s + .08s * $value; }
						.box:nth-child(2) { transition-delay: .7s + .08s * $value; }
					}
				}
			}
			.blog-list .item {
				.img, .title, .excerpt, .date	{ transition: opacity .6s, transform .6s $easeOutCubic; }
				// timing
				@for $value from 1 through 2 {
					&:nth-child(2n+#{$value}) {
						.img	{ transition-delay: .6s + .08s * $value; }
						.title	{ transition-delay: .65s + .08s * $value; }
						.excerpt	{ transition-delay: .7s + .08s * $value; }
						.date	{ transition-delay: .75s + .08s * $value; }
					}
				}
			}
			.security-section > * {
				transition: opacity .4s, transform .4s $easeOutCubic;
				@for $value from 1 through 5 {
					&:nth-child(#{$value}) { transition-delay: .5s + .05s * $value; }
				}
			}
		}
		.wp-pagenavi,
		.c-pageLead {
			transition: opacity .4s, transform .4s $easeOutCubic;
			transition-delay: .5s;
		}
	}
	&.in {
		.l-background { transition-duration: .8s; }
		
		.l-main .l-cover {
			transition: opacity .6s .1s, transform .6s .1s $easeOutCubic;
		}
		// 最後にヘッダー (1600ms で paging 消えるのでそれまでに出し切る)
		.l-header .inner {
			transition-delay: 1s;
			transition-duration: .6s;
		}
	}
}

body.in {
	&.send {
		.works-intro .l-visual {
			opacity: 0;
			transform: translateY(32px);
		}
		.l-pageTitle {
			.wrap > span,
			&::after { opacity: 0; }
			.wrap > span { transform: translateY(1.25em); }
			&::after { height: 0; }
		}
		.works-list .item,
		.welfare-list .item,
		.interview-list .item .img,
		.interview-list .item .box,
		.blog-list .item .img,
		.blog-list .item .title,
		.blog-list .item .excerpt,
		.blog-list .item .date,
		.security-section > *,
		.wp-pagenavi,
		.c-pageLead,
		.p-error {
			opacity: 0;
			transform: translateY(16px);
		}
		// アクセス時
		#page:not(.anima) .l-header .inner {
			opacity: 0;
			transform: translateY(-16px);
		}
		#page .l-main .l-cover {
			opacity: 0;
			transform: translateX(-16%);
		}
	}
}

body.out {
	&.paging:not(.toTop) {
		.l-background { background-position: 44% 0 !important; }
	}
	
	&.send {
		// 共通
		.l-header .inner,
		.l-footer .pagetop,
		.l-footer .inner {
			opacity: 0;
			transform: translateY(-16px);
		}
		.l-footer .inner {
			transform: translateY(16px);
		}
		// トップページ
		.l-kv {
			opacity: 0;
		}
		.l-kv .l-cover {
			@include OVER() {
				transform: translateX(-8%);
			}
			@include UNDER() {
				transform: translateY(-32px);
			}
		}
		// 下層
		.l-main .l-cover {
			opacity: 0;
			transform: translateX(-16%);
		}
	}
	
	// -------------------------------------------------- poylgon で消える準備
	&:not(.send) {
		// トップページ
		.kv-title .tx-mask,
		.kv-copy .tx-mask,
		.kv-description .tx-mask,
		.l-kv .c-scroll,
		.works-intro .txt,
		.interview-list .img,
		.interview-list .box,
		.blog-list .img,
		.blog-list .title,
		.blog-list .excerpt,
		.blog-list .date.welfare-list .item {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
		.c-link {
			clip-path: polygon(0 -2%, 100% -2%, 100% 102%, 0 102%);
		}
		// 下層
		.c-topTitle .wrap > span,
		.c-topTitle::after,
		.l-intro .lead .wrap,
		.l-intro .copy .box,
		.l-intro .txt .wrap,
		.c-boxTxt .box,
		.l-entry .c-topTitle .wrap,
		.l-entry .entry-btn,
		.message-intro .l-visual,
		.success-list .name,
		.success-list .box,
		.success-list .txt,
		.success-list .img,
		.success-list .illust,
		.reason-list .item,
		.works-intro .l-visual,
		.works-list .item,
		.welfare-list .item,
		.wp-pagenavi,
		.c-pageLead,
		.p-error {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
		.message-intro .l-visual,
		.works-intro .l-visual {
			clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
		}
	}
	// -------------------------------------------------- poylgon で消えた状態
	&.send {
		// トップページ
		.kv-title .tx-mask,
		.kv-copy .tx-mask,
		.kv-description .tx-mask,
		.l-kv .c-scroll,
		.works-intro .txt,
		.interview-list .img,
		.interview-list .box,
		.blog-list .img,
		.blog-list .title,
		.blog-list .excerpt,
		.blog-list .date,
		.c-link {
			opacity: 0;
			clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
		}
		// 下層
		.c-topTitle .wrap > span,
		.c-topTitle::after,
		.l-intro .lead .wrap,
		.l-intro .copy .box,
		.l-intro .txt .wrap,
		.c-boxTxt .box,
		.l-entry .c-topTitle .wrap,
		.l-entry .entry-btn,
		.success-list .name,
		.success-list .box,
		.success-list .txt,
		.success-list .img,
		.success-list .illust,
		.reason-list .item,
		.works-list .item,
		.welfare-list .item,
		.wp-pagenavi,
		.c-pageLead,
		.p-error {
			opacity: 0;
			clip-path: polygon(0 100%, 100% 100%, 100% 100%, 0 100%);
		}
		.message-intro .l-visual,
		.works-intro .l-visual {
			opacity: 0;
			clip-path: polygon(100% 0, 100% 0, 100% 100%, 100% 100%);
		}
		.security-section > *,
		.l-entry::before {
			opacity: 0;
		}
	}
}
