@charset "utf-8";

/*
	.l-loading: ローダー
	.l-section: 下層セクション
	.l-cover:   下層カバー
	.l-toggle:  スマホ時のグローバルメニュー
	.logo:      ロゴ
	.l-header:  ヘッダー
	.l-entry:   ENTRY バナー
	.l-footer:  フッター
*/

#page {
	position: relative;
	overflow: hidden;
	letter-spacing: .24em;
}
.l-main {
	margin: 96px auto 0;
	@include UNDER() {
		padding: 0 6.4%;
    body:not(#indexPage) & {
      margin-top: 120px;
    }
	}
	* {
		box-sizing: border-box;
	}
}
.l-topContents .l-main {
	max-width: 980px;
}

.l-contents .l-intro,
.l-pageTitle,
.l-section {
	max-width: 980px;
	margin-right: auto;
	margin-left: auto;
}
#page.anima .l-header {
	transition: opacity .4s, transform .4s $easeOutCubic;
}

.l-background {
	position: fixed;
	top: 0;
	left: 0;
	z-index: -1;
	width: 100%;
	height: 100%;
	background: #0c0a26 url(/img/common/bg.png) 50% 0 / calc(260px / 2) auto;
	will-change: background-position;
}

// -------------------------------------------------- ローダー
.l-loading {
	position: fixed;
	top: 0;
	left: 0;
	width: 100vw;
	height: 100vh;
	pointer-events: none;
}
.l-loader {
	position: absolute;
	top: calc(50% - 32px);
	left: calc(50% - 32px);
	text-align: center;
	transition: opacity .2s,transform .3s cubic-bezier(.215,.61,.355,1);
	svg {
		width: 64px;
		height: 64px;
		vertical-align: middle;
		fill: none;
		stroke: #412ea9;
		stroke-width: 3;
		animation: loading 1s linear infinite;
	}
}
#page:not(.loading) .l-loader {
	opacity: 0;
	transform: scale(.5);
}
@keyframes loading {
	from { transform: none; }
	to { transform: rotate(360deg); }
}

// -------------------------------------------------- 下層セクション
.l-section {
	@include UNDER() {
    margin-top: 64px;
    #blogPage &,
    #interviewPage & {
      margin-top: 0;
    }
	}
  @include OVER() {
    #conceptPage & {
      margin-top: 120px;
    }
  }
}

// -------------------------------------------------- 下層カバー
.l-cover {
	position: absolute;
	z-index: -1;
	color: #553dcc;
	&::after {
		position: absolute;
		width: 100%;
		height: 100%;
		content: "";
		background: currentcolor;
	}
}
.l-main .l-cover {
	top: 0;
	left: 0;
	width: 40vw;
	height: 80vw;
	&::after {
		transform: rotate(30deg);
		transform-origin: 100% 0;
		@include UNDER() {
			transform: translateZ(0) rotate(45deg);
		}
	}
}
@include UNDER() {
	.l-kv .l-cover {
		top: calc(-100vh - 50vw);
		left: 0;
		width: 100%;
		height: calc(100vh + 100vw);
		transform: translateY(100%) translateY(100vh);
		transform: translateY(calc(100% + 100vh));
		&::after {
			transform: skewY(-45deg);
		}
	}
	.l-main .l-cover {
		width: 110vw;
		height: 220vw;
	}
}
@include OVER() {
	.l-kv .l-cover {
		top: 0;
		//right: calc(-58vh / 2);
		left: calc(-100vw - 26vh);
		width: calc(100% + 58vh + 140px);
		height: 100vh;
		transform: translateX(256vw);
	}
	.l-kv .l-cover::after {
		transform: skewX(-30deg);
	}
	.l-container {
		.l-kv .l-cover {
			transform: translateX(100%) translateX(100vw);
			transform: translateX(calc(100% + 100vw));
		}
	}
}

// -------------------------------------------------- スマホ時のグローバルメニュー
@include OVER() {
	.l-toggle { display: none; }
}
@include UNDER() {
	.l-toggle,
	.l-toggleWrap {
		position: fixed;
		top: 0;
		right: 0;
		width: 100vw;
		height: 100vh;
	}
	.l-toggleWrap {
		background: rgba(0,0,0,.2);
		transition: opacity .6s;
		&.-hidden {
			pointer-events: none;
			opacity: 0;
		}
    &.type1 {
      z-index: 9997;
    }
    &.type2 {
      z-index: 9998;
    }
	}
	.l-toggle {
		box-sizing: border-box;
		max-width: 360px;
		padding: 64px 6.4%;
		background: #553dcc;
    &.type1 {
      z-index: 9998;
    }
    &.type2 {
      z-index: 9999;
      background: #553dcc;
    }
		.l-gnav {
			a {
				display: block;
				padding: 14px 0;
				line-height: 1;
				color: #fff;
			}
			.en {
				display: block;
			}
			.jp {
				color: #ac9eff;
			}
			.c-btn {
				display: none;
			}
		}
		
		// ENTRY
		.entry-btn {
			margin-top: 64px;
			a {
				position: relative;
				display: block;
				height: auto;
				padding: 9px 20px;
				text-align: left;
				background: #fff;
				span {
					color: #553dcc;
				}
			}
			.ico {
				right: 24px;
				width: 17px;
				height: 12px;
				background: url(/img/common/icon_www_gnav.svg) center / 17px auto no-repeat;
			}
		}
		
		// close button
		.c-tglBtn {
			position: absolute;
			top: 24px;
			right: 6.4vw;
			button {
				span {
					width: 30px;
					height: 5px;
					&:nth-child(1) { transform: rotate(-45deg); }
					&:nth-child(2) { transform: rotate(45deg); }
				}
			}
		}
		
		// transition
		.item,
		.entry-btn {
			transition: opacity .3s, transform .3s $easeOutCubic;
		}
		transition: transform .4s $easeOutCubic;
		// 非表示
		&:not(.-show) {
			overflow: hidden;
			//visibility: hidden;
			pointer-events: none;
			transform: translateX(100%);
			
			.item,
			.entry-btn {
				opacity: 0;
				transition-delay: .3s;
				transition-duration: 0s;
				transform: translateY(-100%);
			}
		}
		// 表示
		&.-show {
			overflow-y: scroll;
			.item {
				@for $value from 1 to 8 {
					&:nth-of-type(#{$value}) { transition-delay: .05s * $value; }
				}
			}
			.entry-btn {
				transition-delay: .35s;
				transition-duration: .4s;
			}
		}
	}
}
@include UNDER(767px) {
	.l-toggleWrap {
		background: #553dcc;
	}
	.l-toggle {
		max-width: none;
		background: none;
		transition: opacity .3s;
		&:not(.-show) {
			opacity: 0;
			transform: none;
		}
	}
}

// -------------------------------------------------- ロゴ (header/footer)
.logo {
	@include OVER() {
		.mark {
			width: 31px;
			height: 37px;
		}
		.type {
			width: 167px;
			height: 23px;
			margin-left: 30px;
		}
	}
	@include UNDER() {
    .is-fixed &,
    .is-active & {
      display: none;
    }
		.mark {
			width: 27px;
			height: 41px;
		}
		.type {
			width: 147px;
			height: 30px;
			margin-left: 24px;
		}
		img {
			width: 100%;
			height: auto;
		}
	}
}

// -------------------------------------------------- ヘッダー
.l-header,
.l-toggle {
	a {
		display: block;
		color: #fff;
	}
}
.l-header {
	position: relative;
	z-index: 1000;
	@include OVER() {
		height: 104px;
	}
	@include UNDER() {
		position: absolute;
		top: 0;
		left: 0;
		z-index: 100;
		width: 100vw;
		height: 90px;
	}
	.logo {
		a {
			display: flex;
			align-items: center;
		}
	}
	.inner {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include OVER() {
			&.v-sp { display: none; }
			min-width: 980px;
			max-width: 1920px;
			height: 96px;
			padding: 0 48px 0 3.4%;
			padding-top: 32px;
			margin: 0 auto;
		}
		@include UNDER() {
			&.v-pc { display: none; }
			padding: 24px 6.4% 0;
		}
	}
	.l-gnav {
		@include OVER() {
			margin-left: auto;
		}
	}
}
@include OVER() {
	// gnav
	.gnav-list {
		display: flex;
		align-items: center;
		justify-content: center;
		.item {
			margin: 0 12px;
			a {
				position: relative;
				display: block;
				padding: 8px 0;
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					content: "";
					border-bottom: 2px solid #553dcc;
					transition: transform .3s $easeOutCubic;
				}
				&:not(:hover)::after {
					transform: scaleX(0);
					transform-origin: 100% 0;
				}
				&:hover::after {
					transform: none;
					transform-origin: 0 0;
				}
			}
		}
		// ENTRY
		.c-btn {
			display: inline-block;
			width: 126px;
			margin-left: 37px;
			vertical-align: middle;
			a {
				display: block;
				height: 32px;
				text-align: center;
			}
			&.ef {
				.en, .large, a::before {
					position: absolute;
					top: 0;
					left: 0;
					width: 100%;
					height: 100%;
					transition: opacity .3s, transform .3s $easeOutCubic;
				}
				a::before {
					letter-spacing: .24em;
					content: attr(data-text);
				}
				a:not(:hover)::before {
					opacity: 0;
					transform: translateY(1em);
				}
				a:hover .en, .large {
					opacity: 0;
					transform: translateY(-1em);
				}
			}
		}
	}
}
@include UNDER() {
  
	.l-header {
		.c-tglBtn button {
			span:nth-child(1) { transform: translate(-4px, -12px); }
			span:nth-child(2) { transform: translate(4px, 0); }
			span:nth-child(3) { transform: translate(-4px, 12px); }
		}
		&:not(.type1).is-fixed {
			position: fixed;
			transform: translateZ(0) translateY(-100%);
		}
		&:not(.type1).is-active {
			position: fixed;
			transform: translateZ(0) translateY(0);
		}
		&:not(.type1).enableTransition {
			transition: transform .3s cubic-bezier(.55,.09,.68,.53);
		}
		&:not(.type1).is-active.enableTransition {
			transition: transform .3s cubic-bezier(.25,.46,.45,.94);
		}
	}
  div.l-header {
    width: 100px;
    right: 6.4%;
    left: inherit;
    visibility: hidden;
    .inner {
      padding-left: 0;
      padding-right: 0;
    }
    &.is-fixed,
    &.is-active,
    &.enableTransition,
    &.is-active.enableTransition {
			visibility: visible;
		}
  }
  body.send div.l-header,
  body.in div.l-header {
    visibility: hidden;
  }
}

// -------------------------------------------------- ENTRY バナー
.l-entry {
	position: relative;
	@include OVER() {
		display: flex;
		align-items: center;
		min-height: 500px;
		margin-top: 155px;
	}
	@include UNDER() {
		min-height: 763px;
		margin-top: 55px;
	}
	&::before,
	.cover {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
	&::before {
		content: "";
		background: url(/img/common/entry_bg.png) center / cover no-repeat;
		@include UNDER() {
			background-image: url(/img/common/entry_bg_sp.png);
		}
	}
	.cover {
		left: -39vh;
		z-index: 1;
		width: calc(100vw + 56vh);
		background: #553dcc;
		transform: skewX(-30deg);
		transform-origin: 50% 50%;
	}
	.inner {
		width: 100%;
		max-width: 1000px;
		padding: 0 32px;
		margin: 0 auto;
		@include OVER() {
			box-sizing: content-box;
		}
		@include UNDER() {
			padding: 32px 6.4% 0;
		}
	}
	.c-topTitle {
		padding: 0!important;
		margin: 0!important;
		.wrap {
			display: block;
		}
		&::after {
			display: none;
		}
	}
	.c-boxTxt {
		margin-top: 15px;
		.box::before {
			z-index: auto;
		}
	}
	.entry-btn {
		@include OVER() {
			width: 360px;
			margin-top: 56px;
			+ .entry-btn { margin-top: 24px; }
		}
		@include UNDER() {
			position: absolute;
			bottom: 115px;
			left: 6.4%;
			width: calc(100% - 12.8%);
			+ .entry-btn { bottom: 16px; }
		}
		a {
			display: block;
			padding: 16px 32px;
			box-sizing: border-box;
			background: linear-gradient(to left,#553dcc 320px,rgba(85,61,204,0) 1600px) 100% 0 / 1920px 40px;
			@include UNDER() {
				padding: 16px 32px;
			}
			> span {
				position: relative;
				display: block;
				overflow: hidden;
				line-height: 1.5;
				color: #fff;
        text-transform: none;
				&::after {
					position: absolute;
					top: 0;
					left: 0;
					content: attr(data-text);
				}
				span,
				&::after {
					transition: opacity .3s, transform .3s $easeOutCubic;
				}
				&:nth-child(2) span,
				&:nth-child(2)::after { transition-delay: .1s; }
			}
			.jp {
				margin-top: 4px;
			}
			&:not(:hover) > span::after {
				opacity: 0;
				transform: translateY(1.6em);
			}
			&:hover {
				background-position: 0 0;
				> span span {
					opacity: 0;
					transform: translateY(-1.6em);
				}
			}
		}
	}
	// 表示前 (in-view)
	&.js-inview:not(.in-view) {
		&::before {
			opacity: 0;
		}
		.cover {
			transform: translateX(100%) skew(-30deg);
		}
		.inner,
		.c-topTitle .wrap span,
		.c-boxTxt .box .wrap span,
		.entry-btn {
			opacity: 0;
			transform: translateY(32px);
		}
		.c-topTitle .wrap {
			span { transform: translateY(100%); }
		}
		.c-boxTxt .box {
			&::before { transform: scaleX(0); }
			.wrap span { transform: translateY(100%); }
		}
		//.entry-btn { transform: translateY(16px); }
	}
	// transition (in-view)
	&.js-inview.in-view {
		&::before {
			transition: opacity 0s .3s;
		}
		.cover {
			transition: transform .4s $easeOutCubic;
			transform: skew(-30deg);
//      @supports (-ms-ime-align: auto) {
//        /* autoprefixer: ignore next */
//        transition: transform .4s $easeOutCubic;
//        /* autoprefixer: ignore next */
//        transform: skew(-30deg);
//      }
		}
		.inner,
		.entry-btn {
			transition: opacity .6s, transform .6s $easeOutCubic;
		}
		.c-topTitle .wrap span,
		.c-boxTxt .box::before,
		.c-boxTxt .box .wrap span {
			transition: opacity .6s, transform .6s $easeOutCubic;
		}
		// 表示スピードと表示順 (in-view)
		.c-topTitle .wrap {
			&:nth-child(1) span { transition-delay: .6s; }
			&:nth-child(2) span { transition-delay: .7s; }
		}
		.c-boxTxt .box .wrap span { transition-duration: .5s; }
		.c-boxTxt .box:nth-of-type(1) {
			&::before { transition-delay: .8s; }
			@for $value from 1 to 20 {
				.wrap span:nth-child(#{$value}) { transition-delay: .025s * $value + .8s; }
			}
		}
		.c-boxTxt .box:nth-of-type(2) {
			&::before { transition-delay: .9s; }
			@for $value from 1 to 20 {
				.wrap span:nth-child(#{$value}) { transition-delay: .025s * $value + .9s; }
			}
		}
		.entry-btn {
			transition-delay: 1.2s;
			transition-duration: .6s;
			+ .entry-btn { transition-delay: 1.3s; }
		}
	}
	&.js-inview.in-view.fix {
		.cover {
			transition-duration: .6s;
			transform: translateX(-100%) skew(-30deg);
		}
	}
}

// -------------------------------------------------- フッター
.l-footer {
	position: relative;
	.inner {
		@include OVER() {
			display: flex;
			align-items: center;
			padding: 56px	48px 60px 3.4%;
		}
		@include UNDER() {
			padding: 56px 6.4% 24px;
		}
	}
	.logo {
		margin-bottom: 8px;
		@include UNDER() {
			margin-bottom: 16px;
		}
		.type {
			@include UNDER() {
				margin-left: 14px;
			}
			img {
				@include UNDER() {
					width: 100%;
					height: auto;
				}
			}
		}
		a {
			display: flex;
			align-items: center;
		}
	}
	
	// fnav
	.fnav {
		margin-left: auto;
		a {
			display: block;
			color: #6c6981;
			transition: color .3s;
			&:hover {
				color: #fff;
			}
		}
	}
	.fnav-list {
		display: flex;
		align-items: center;
		letter-spacing: .24em;
		@include OVER() {
			justify-content: flex-end;
			margin-right: -8px;
		}
		@include UNDER() {
			flex-wrap: wrap;
			margin: 0 -12px;
		}
		.item {
			@include OVER() {
				margin: 0 8px;
			}
			@include UNDER() {
				margin-bottom: 6px;
				&:not(:last-child) {
					margin-right: 4px;
				}
			}
		}
		a {
			position: relative;
			padding-bottom: 4px;
			@include UNDER() {
				padding: 6px 12px;
			}
			@include OVER() {
				&::after {
					position: absolute;
					bottom: 0;
					left: 0;
					width: 100%;
					content: "";
					border-bottom: 1px solid currentcolor;
					transition: transform .3s $easeOutCubic;
				}
				&:not(:hover)::after {
					transform: scaleX(0);
					transform-origin: 100% 0;
				}
				&:hover::after {
					transform: none;
					transform-origin: 0 0;
				}
			}
		}
	}
	
	// contentsinfo
	.contentsinfo {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		margin-top: 16px;
		letter-spacing: .24em;
    @include OVER() { font-size: 10px; }
	}
	.copyright {
		margin-left: 32px;
		color: #6c6981;
		@include UNDER() {
			margin-left: 0;
			font-size: 10px;
			text-align: right;
			transform: scale(0.8);
		}
	}
	
	// pagetop
	.pagetop {
		position: absolute;
		@include OVER() {
			top: -112px;
			right: 25px;
		}
		@include UNDER() {
			top: 57px;
			right: 6.4%;
		}
		a {
			position: relative;
			display: block;
			&::before {
				position: absolute;
				top: calc(50% - 4px);
				right: 16px;
				width: 8px;
				height: 8px;
				content: "";
				border-top: 2px solid #fff;
				border-right: 2px solid #fff;
				transition: transform .3s $easeOutCubic;
				transform: rotate(-45deg);
			}
			&:hover::before {
				transform: rotate(-45deg) translate(2px, -2px);
			}
		}
		svg {
			fill: none;
			stroke: #412ea9;
			stroke-width: 3;
		}
	}
}