@charset "utf-8";
.error404 {
  .l-entry {
    display: none;
  }
}
.p-error {
  padding-top: 40px;
  padding-bottom: 40px;
  text-align: center;
  @include OVER() {
    padding-bottom: 150px;
  }
  &::before {
    display: block;
    width: 135px;
    height: 155px;
    margin: 0 auto 20px;
    content: "";
    background: url("/img/common/icon_404.png") no-repeat center;
    background-size: 100% auto;
  }
  &__title {
    margin-bottom: 20px;
    font-size: 40px;
    text-transform: uppercase;
    letter-spacing: 0.24em;
    @include font-EN;
    @include UNDER() {
      font-size: 32px;
    }
  }
  &__entry {
    p {
      margin-bottom: 10px;
      font-size: 14px;
    }
  }
  &__back {
    display: inline-block;
    width: 126px;
    margin-top: 25px;
    a {
      height: 40px;
      line-height: 40px;
      color: #fff;
    }
    &.c-btn {
      a {
        padding: 0 10px;
      }
      &.ef {
        .en, a::before {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          font-size: 11px;
          transition: opacity .3s, transform .3s $easeOutCubic;
        }
        a::before {
          font-size: 11px;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: .24em;
          letter-spacing: .24em;
          content: attr(data-text);
        }
        a:not(:hover)::before {
          opacity: 0;
          transform: translateY(1em);
        }
        a:hover .en {
          opacity: 0;
          transform: translateY(-1em);
        }
      }
    }
  }
}