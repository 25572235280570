@charset "utf-8";

body {
	box-sizing: border-box;
	font-family: "Noto Sans Japanese",sans-serif;
	font-size: 16px;
	font-weight: 200;
	line-height: 1.6;
	color: #fff;
	background: #0c0a26 url(/img/common/bg.png) center top / calc(260px / 2) fixed;
}

ul {
	list-style: none;
}
img {
	width: 100%;
	height: auto;
	vertical-align: middle;
	border: none;
	border-style: none;
}
a {
	text-decoration: inherit;
}

// currentPage
#indexPage a[href*="01_top.html"],
#conceptPage a[href*="02_concept.html"],
#worksPage a[href*="03_about.html"],
#interviewPage a[href*="04_Interview.html"],
#blogPage a[href*="05_blog.html"],
#welfarePage a[href*="06_welfare.html"],
#securityPage a[href*="07_security_info.html"] {
	pointer-events: none;
	@include OVER() {
		&::after { transform: none; }
	}
}